<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách module</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="getData()" @click="getData()" class="md-icon-button md-dense md-raised md-primary"> 
                            <md-icon>cached</md-icon> 
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button> 
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="table-content">
                    <ul>
                        <li v-for="item in data" :key="item.id">
                            <div style="padding-left:10px; font-weight:500;" class="item">
                                <div>{{item.moduleName}}</div>
                                <div class="action">
                                    <router-link :to="'/module/crud/' + item.id">
                                        <md-button class="md-fab md-mini md-second">
                                            <md-icon>edit</md-icon>
                                            <md-tooltip>Sửa</md-tooltip>
                                        </md-button>
                                    </router-link>
                                    <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                </div>
                            </div>
                            <ul>
                                <li v-for="sub in item.children" :key="sub.id">
                                    <div style="padding-left:80px;" class="item">
                                        <div>|_ {{sub.moduleName}}</div>
                                        <div class="action">
                                            <router-link :to="'/module/crud/' + sub.id">
                                                <md-button class="md-fab md-mini md-second">
                                                    <md-icon>edit</md-icon>
                                                    <md-tooltip>Sửa</md-tooltip>
                                                </md-button>
                                            </router-link>
                                            <md-button v-on:click="confirmDelete(sub.id)" class="md-fab md-mini md-plain">
                                                <md-icon>clear</md-icon>
                                                <md-tooltip>Xóa</md-tooltip>
                                            </md-button>
                                        </div>
                                    </div>
                                    
                                    <ul>
                                        <li v-for="s in sub.children" :key="s.id">
                                            <div style="padding-left:150px;" class="item">
                                                <div>|_ {{s.moduleName}}</div>
                                                <div class="action">
                                                    <router-link :to="'/module/crud/' + s.id">
                                                        <md-button class="md-fab md-mini md-second">
                                                            <md-icon>edit</md-icon>
                                                            <md-tooltip>Sửa</md-tooltip>
                                                        </md-button>
                                                    </router-link>
                                                    <md-button v-on:click="confirmDelete(s.id)" class="md-fab md-mini md-plain">
                                                        <md-icon>clear</md-icon>
                                                        <md-tooltip>Xóa</md-tooltip>
                                                    </md-button>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
import moduleService from '../../../api/moduleService';
import messageBox from '../../../utils/messageBox';
import { mapActions } from 'vuex';

export default {
    metaInfo: {
        title: 'Danh sách module'
    },
    data() {
        return {
            loadding: false,
            data: [],
            id: 0
        }
    },
    created(){
        this.getData();
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        refresh(){
            this.getData();
        },

        getData(){
            this.loadding = true;
            moduleService.getAll().then((response) => {
                if(response.statusCode == 200){
                    this.data = response.data;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.loadding = false; });
        },

        confirmDelete(id){
            this.$modal.show('dialog', {
            title: 'Thông báo',
            text: 'Bạn có chắc muốn xóa không?',
            buttons: [
                {
                    title: 'Hủy',
                    handler: () => {
                        this.$modal.hide('dialog')
                    }
                },
                {
                    title: 'Xóa',
                    handler: () => {
                        this.del(id)
                    }
                }
            ]
            })
        },

        del(id){
            this.$modal.hide('dialog');
            this.setLoading(true);
            moduleService.delete(id).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Xóa thành công");
                    this.refresh();
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        add(){
            this.$router.push('/module/crud');
        },
    }
}
</script>


<style scoped>
    li button {
        height: 28px !important;
        width: 28px !important;
    }
    .item {
        display: flex;
        height: 50px;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #eee;
    }
    .item .action i {
        font-size: 14px!important;
        color: #fff!important;
    }
</style>